<!--
 * @Description: 全局常规通用数据表格 带搜索栏、分页、操作
 * @Author: xiawenlong
 * @Date: 2020-12-21 19:52:13
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-01-17 19:43:56
-->
<template>
  <div class="table-list">
    <table-search-bar
      v-if="searchForm.length"
      ref="tableSearchBar"
      :search-form="searchForm"
      @search="onSearch"
    ></table-search-bar>
    <div v-if="title || btns.length" class="top-bar">
      <div v-if="title" class="title">{{ title }}</div>
      <el-button v-if="backBtn" class="backBtn" @click="backBtn.method">
        <img src="@/assets/common/back_btn.png" alt="" />
        <span>{{ backBtn.name }}</span>
      </el-button>
      <slot name="filter"></slot>
      <div class="btns">
        <el-button
          v-for="(btn, key) in btns"
          :key="key"
          :type="btn.type || 'default'"
          :style="`width:${btn.width}px`"
          @click.native.prevent="btn.method"
        >
          {{ btn.label }}
        </el-button>
      </div>
    </div>
    <slot name="subTitle"></slot>
    <div class="table-list">
      <div v-if="tabsList.length" class="tabsList">
        <div class="tabsWrap">
          <div v-for="item in tabsList" :key="item.prop" class="tabs">
            <div
              class="tabsList_item"
              :class="{ singleColor: item.currentValue === '' }"
              @click="changeSingleChoice(item, { value: '' })"
            >
              全部
            </div>
            <div
              v-for="child in item.children"
              :key="child.value"
              class="tabsList_item"
              :class="{ singleColor: item.currentValue === child.value }"
              @click="changeSingleChoice(item, child)"
            >
              {{ child.label }}
            </div>
          </div>
        </div>
      </div>
      <el-table
        ref="tableList"
        v-loading="loading"
        :row-key="options && options.rowKey"
        stripe
        :height="options && options.height"
        :border="false"
        :data="data"
        header-align="center"
        tooltip-effect="dark"
        style="width: 100%"
        v-bind="options"
        :highlight-current-row="options && options.singleCurrent"
        @selection-change="handleSelectionChange"
        @current-change="handleSingleChange"
        @header-click="handleHeaderClick"
        @cell-click="handleCellClick"
      >
        <!-- 复选框 -->
        <el-table-column
          v-if="options && options.selection && (!options.isShow || options.isShow())"
          :reserve-selection="options.reserveSelection"
          :selectable="options.selectable"
          type="selection"
          width="55"
        />
        <!-- 索引 -->
        <el-table-column
          v-if="options && options.index"
          width="60"
          align="center"
          type="index"
          :label="options && options.labelIndex"
        />
        <el-table-column
          v-for="(col, index) in columns"
          :key="col.prop"
          :prop="col.prop"
          :label="col.label"
          :align="col.align"
          :width="col.width"
          :min-width="col.minWidth"
          :render-header="col.renderHeader ? col.renderHeader : null"
          :sortable="col.sortable"
        >
          <template v-if="col.sortdata" slot="header">
            <div class="sortHeader" @click="sortSearch(col.sortdata)">
              {{ col.label
              }}<span class="caret-wrapper">
                <i
                  :class="[
                    'sort-caret ascending',
                    { active: ruleType == 1 && ruleName == col.sortdata },
                  ]"
                ></i>
                <i
                  :class="[
                    'sort-caret descending',
                    { active: ruleType == 2 && ruleName == col.sortdata },
                  ]"
                ></i>
              </span>
            </div>
          </template>
          <template slot-scope="scope">
            <template>
              <template v-if="!col.render">
                <template v-if="col.formatter">
                  <span
                    @click="col.click && col.click(scope.row, scope.$index)"
                    v-text="col.formatter(scope.row, col, scope.$index)"
                  ></span>
                </template>
                <template v-else>
                  <el-tooltip
                    v-if="col.twoLines"
                    class="twoLinesRow"
                    popper-class="twoLinesRowTooltip"
                    effect="dark"
                    :content="scope.row[col.prop]"
                    placement="top"
                  >
                    <div>
                      {{ scope.row[col.prop] }}
                    </div>
                  </el-tooltip>
                  <span
                    v-else
                    :style="col.click ? 'color: #409EFF; cursor: pointer;' : null"
                    @click="col.click && col.click(scope.row, scope.$index)"
                  >
                    {{ scope.row[col.prop] }}
                  </span>
                </template>
              </template>
              <template v-else>
                <render :column="col" :row="scope.row" :render="col.render" :index="index"></render>
              </template>
            </template>
          </template>
        </el-table-column>
        <!-- 操作按钮 -->
        <el-table-column
          v-if="operates && operates.length > 0"
          :width="options && options.operatesWidth"
          label="操作"
          align="left"
        >
          <template
            v-if="options && options.operatesSubtitle && options.operatesText"
            slot="header"
          >
            <span class="">操作</span>
            <el-tooltip class="item" effect="dark" placement="bottom">
              <div slot="content" class="toolText">
                {{ options.operatesSubtitle }}<br />{{ options.operatesText }}
              </div>
              <i class="el-icon-question operateText"></i>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <div class="operate-group">
              <template v-for="(btn, key) in operates">
                <span
                  v-if="!btn.isShow || (btn.isShow && btn.isShow(scope.row, scope.$index))"
                  :key="key"
                >
                  <el-button
                    size="mini"
                    :style="btn.color ? `color:${btn.color}` : ''"
                    :type="btn.type || 'text'"
                    :disabled="btn.disabled && btn.disabled(scope.row, scope.$index)"
                    @click.native.prevent="btn.method(scope.row, scope.$index)"
                  >
                    {{ btn.label }}
                  </el-button>
                </span>
              </template>
            </div>
            <div class="operate-group">
              <template v-for="(btn, key) in operates2">
                <span
                  v-if="!btn.isShow || (btn.isShow && btn.isShow(scope.row, scope.$index))"
                  :key="key"
                >
                  <el-button
                    size="mini"
                    :style="btn.color ? `color:${btn.color}` : ''"
                    :type="btn.type || 'text'"
                    :disabled="btn.disabled && btn.disabled(scope.row, scope.$index)"
                    @click.native.prevent="btn.method(scope.row, scope.$index)"
                  >
                    {{ btn.label }}
                  </el-button>
                </span>
              </template>
            </div>
            <div class="operate-group">
              <template v-for="(btn, key) in operates3">
                <span
                  v-if="!btn.isShow || (btn.isShow && btn.isShow(scope.row, scope.$index))"
                  :key="key"
                >
                  <el-button
                    size="mini"
                    :style="btn.color ? `color:${btn.color}` : ''"
                    :type="btn.type || 'text'"
                    :disabled="btn.disabled && btn.disabled(scope.row, scope.$index)"
                    @click.native.prevent="btn.method(scope.row, scope.$index)"
                  >
                    {{ btn.label }}
                  </el-button>
                </span>
              </template>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="pager"
        background
        :pager-count="pager.count"
        :current-page="pager.current"
        :page-sizes="[10, 20, 50, 100, 500]"
        :page-size="pager.size"
        layout="total, prev, pager, next, sizes, jumper"
        :total="pager.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import TableSearchBar from './TableSearchBar'
export default {
  name: 'TableList',
  components: {
    TableSearchBar,
    render: {
      functional: true,
      props: {
        row: Object,
        render: Function,
        index: Number,
        column: {
          type: Object,
          default: null,
        },
      },
      render: (h, opt) => {
        const params = {
          row: opt.props.row,
          index: opt.props.index,
        }
        if (opt.props.column) params.column = opt.props.column
        return opt.props.render(h, params)
      },
    },
  },
  props: {
    tabsList: {
      type: Array,
      default: () => [],
    },
    searchForm: {
      type: Array,
      default: () => [],
    },
    btns: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    operates: {
      type: Array,
      default: () => [],
    },
    operates2: {
      type: Array,
      default: () => [],
    },
    operates3: {
      type: Array,
      default: () => [],
    },
    pager: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    reserveSelection: {
      type: Boolean,
      default: false,
    },
    backBtn: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      params: {},
      ruleName: -1,
      ruleType: 0,
    }
  },
  mounted() {
    this.tabsList.map(item => {
      item.currentValue = item.activeValue || ''
    })
  },
  methods: {
    // 分页
    handleSizeChange(size) {
      this.$emit('size-change', size)
    },
    handleCurrentChange(current) {
      this.$emit('current-change', current)
    },
    onSearch(params) {
      this.params = { ...this.params, ...params }
      this.$emit('search', this.params)
    },
    handleSelectionChange(params) {
      this.$emit('selectionChange', params)
    },
    handleSingleChange(params) {
      this.$emit('singleSelectChange', params)
    },
    // 表头排序（接口
    sortSearch(val) {
      if (this.ruleName !== val) {
        this.ruleName = val
        if (this.ruleType > 0) {
          this.ruleType = 0
        }
      }
      if (this.ruleType == 2) {
        this.ruleType = 0
        this.params = {
          ...this.params,
          ...(this.$refs.tableSearchBar ? this.$refs.tableSearchBar.searchData : {}),
          ruleName: undefined,
          ruleType: undefined,
        }
        this.$emit('search', this.params)
      } else {
        this.ruleType = this.ruleType + 1
        this.params = {
          ...this.params,
          ...(this.$refs.tableSearchBar ? this.$refs.tableSearchBar.searchData : {}),
          ruleName: this.ruleName,
          ruleType: this.ruleType,
        }
        this.$emit('search', this.params)
      }
    },
    changeSingleChoice(item, child) {
      console.log(child)
      if (item.currentValue !== child.value) {
        item.currentValue = child.value
      } else {
        item.currentValue = ''
      }
      let params = {}
      this.tabsList.map(item => {
        params[item.prop] = item.currentValue
      })
      this.params = {
        ...this.params,
        ...(this.$refs.tableSearchBar ? this.$refs.tableSearchBar.searchData : {}),
        ...params,
      }
      this.$emit('search', this.params)
    },
    handleHeaderClick(column) {
      if (column.className == 'el-table-column--selection') {
        this.$refs.tableList.toggleAllSelection()
      }
    },
    handleCellClick(row, column) {
      if (column.className == 'el-table-column--selection') {
        if (this.options.selectable && !this.options.selectable(row)) return
        this.$refs.tableList.toggleRowSelection(row)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.table-list {
  .twoLinesRow {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }
  .top-bar {
    height: 40px;
    padding: 17px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 18px;
      font-weight: 500;
      color: #4d4e5d;
    }
    .btns {
      margin-left: auto;
    }
    .el-button {
      margin: 0;
      margin-right: 20px;
      &:last-of-type {
        margin: 0;
      }
    }
  }
  ::v-deep .backBtn {
    box-sizing: border-box;
    width: 122px;
    height: 27px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-left: 18px !important;
    font-size: 14px;
    color: #606266;
    > span {
      width: 122px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 15px;
      height: 14px;
      margin-right: 3px;
    }
  }
  .operate-group {
    > span {
      margin-right: 10px;
      &:last-of-type {
        margin: 0;
      }
    }
  }
  .sortHeader {
    height: 31px;
    cursor: pointer;
    i {
      &.ascending.active {
        border-bottom-color: #ff7b33;
      }
      &.descending.active {
        border-top-color: #ff7b33;
      }
    }
  }
  .tabsList {
    background-color: #fff;
    padding: 0 0 0 10px;
    overflow: hidden;
    .tabsWrap {
      display: flex;
      border-bottom: 1px solid #e6e6e6;
      padding: 20px 0 15px;
    }
    .tabs {
      display: flex;
      line-height: 40px;
      font-size: 14px;
      border: 1px solid #f0f0f0;
      text-align: center;
      border-radius: 4px;
      margin-right: 80px;
      position: relative;
      background: #fafafa;
      &:last-child {
        margin-right: 0;
      }
      &:last-child::after {
        display: none;
      }
      &::after {
        position: absolute;
        content: '';
        right: -40px;
        top: 50%;
        transform: translateY(-50%);
        background-color: #e2e2e2;
        width: 1px;
        height: 20px;
      }
      .tabsList_item {
        padding: 0 28px;
        box-sizing: border-box;
        background: #fafafa;
        border-right: 1px solid #f0f0f0;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: #909399;
        word-break: keep-all;
        &:last-child {
          border-radius: 0 4px 4px 0;
          border-right: 0;
        }
        &:first-child {
          border-radius: 4px 0 0 4px;
        }
      }
    }
  }
  .singleColor {
    background-color: #ff9459 !important;
    color: #fff !important;
  }
  ::v-deep.el-table-column--selection {
    cursor: pointer;
    .cell {
      padding: 0 10px;
    }
  }
}
</style>
<style lang="scss">
.el-tooltip__popper {
  max-width: 60% !important;
}
.operateText {
  padding-left: 5px !important;
}
.toolText {
  width: 300px;
  line-height: 20px !important;
}
</style>
