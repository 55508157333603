<!--
 * @Description: 表格搜索栏
 * @Author: xiawenlong
 * @Date: 2020-12-21 18:06:30
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-01-12 19:18:35
-->
<template>
  <el-form
    ref="tableSearchForm"
    :model="searchData"
    class="table-search-bar"
    :inline="true"
    @submit.native.prevent
    @keyup.enter.native="onSearch"
  >
    <div class="form">
      <el-form-item v-for="item in searchForm" :key="item.prop" :prop="item.prop">
        <!-- :label="item.label ? item.label + ':' : ''" -->
        <el-cascader
          v-if="item.type === 'treeSelect'"
          v-model="searchData[item.prop]"
          :placeholder="item.placeholder || '请选择' + item.label + '(可搜索)'"
          :props="item.optionProps"
          :show-all-levels="item.showLevels"
          :options="item.options"
          filterable
          :clearable="item.clearable"
        ></el-cascader>
        <el-date-picker
          v-if="item.type === 'datetime'"
          v-model="searchData[item.prop]"
          type="datetime"
          :placeholder="item.placeholder || '请选择' + item.label"
        >
        </el-date-picker>
        <el-select
          v-if="item.type === 'select'"
          v-model="searchData[item.prop]"
          :clearable="item.clearable"
          :filterable="item.filterable"
          :placeholder="
            item.placeholder || '请选择' + item.label + (item.filterable ? '(可搜索)' : '')
          "
          @change="
            e => {
              item.onChange && item.onChange(e)
            }
          "
        >
          <el-option
            v-for="option in item.children"
            :key="option.value"
            :value="option.value"
            :label="option.label"
          />
        </el-select>
        <el-date-picker
          v-else-if="item.type === 'picker'"
          v-model="searchData[item.prop]"
          clearable
          style="width: 100%;"
          type="daterange"
          range-separator="至"
          :start-placeholder="item.label || '开始时间'"
          :end-placeholder="item.label || '结束时间'"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="item.pickerDate"
        />
        <span v-if="item.type === 'section'" class="sectionWrapper">
          <el-input
            v-model="searchData[item.propLeft]"
            class="section"
            clearable
            :placeholder="item.placeholder || item.label"
            maxlength="7"
            onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
          ></el-input>
          <span>—</span>
          <el-input
            v-model="searchData[item.propRight]"
            class="section"
            clearable
            :placeholder="item.placeholder || item.label"
            maxlength="7"
            onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
          ></el-input
        ></span>
        <el-input
          v-if="!item.type || item.type === 'input'"
          v-model="searchData[item.prop]"
          clearable
          :placeholder="item.placeholder || '请输入' + item.label"
        ></el-input>
      </el-form-item>
      <div class="btns">
        <el-form-item>
          <template v-if="searchForm.length">
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button @click="resetSearchForm">重置</el-button>
          </template>
        </el-form-item>
      </div>
    </div>
  </el-form>
</template>
<script>
export default {
  name: 'TableSearchBar',
  props: {
    searchForm: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchData: {},
    }
  },
  mounted() {
    this.resetSearchForm()
  },
  methods: {
    // 重置搜索条件
    resetSearchForm() {
      this.searchForm.forEach(element => {
        if (element.defaultValue || element.defaultValue == 0) {
          if (element.prop) {
            this.$set(this.searchData, element.prop, element.defaultValue)
          } else {
            this.$set(this.searchData, element.propLeft, element.defaultValue)
            this.$set(this.searchData, element.propRight, element.defaultValue)
          }
        } else {
          if (element.prop) {
            this.$set(this.searchData, element.prop, '')
          } else {
            this.$set(this.searchData, element.propLeft, '')
            this.$set(this.searchData, element.propRight, '')
          }
        }
      })
    },
    //重置单个搜索框
    resetSearchInput(prop, value = '') {
      this.$set(this.searchData, prop, value)
    },
    onSearch() {
      this.$emit('search', this.searchData)
    },
  },
}
</script>
<style lang="scss" scoped>
.table-search-bar {
  box-sizing: border-box;
  padding: 20px 20px 00px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  background-color: #fff;
  .el-form-item {
    margin-bottom: 20px;
    margin-right: 20px;
    .el-input,
    .el-select,
    .el-date,
    .el-date-editor {
      width: 235px !important;
      &.section {
        width: 104px !important;
      }
    }
  }
  .sectionWrapper {
    span {
      color: #909399;
      margin: 0 8px;
    }
  }
  .btns {
    display: inline-block;
    ::v-deep .el-form-item__content {
      display: flex;
      flex-wrap: nowrap;
    }
    .el-button {
      margin: 0 20px 0 0;
    }
  }
}
</style>
